export const CHARACTERISTICS_COMM_NO = [
  ["Jeg forklarer gjerne ting.", "Jeg hjelper gjerne."],
  ["Jeg føler lett medfølelse.", "Jeg er alltid klar til å inngå kompromisser."],
  ["Jeg er vennlig mot alle.", "Jeg er samvittighetsfull."],
  ["Jeg beundrer sterke personligheter.", "Jeg trekker meg lett tilbake ved konflikter."],
  ["Jeg er sjenert - engestelig.", "Jeg bestrider ting fort."],
  ["Jeg er ikke redd for å tvile på eller stille spørsmål ved allment aksepterte ting.", "Jeg lar meg ikke overkjøre av andre."],
  ["I konflikter står jeg ved min mening.", "Jeg vil alltid være best."],
  ["Når ting ikke går effektivt, organiserer jeg det heller selv.", "Jeg er stille om min personlige mening for å unngå konflikter."],
  ["Jeg beskytter mennesker som er usikre.", "Jeg holder meg til avtaler."],
  ["Jeg synes det er forferdelig når andre ikke liker meg.", "Jeg er beskjeden."],
  ["Jeg gjør alltid mitt beste for å følge med på kjedelige forklaringer.", "Jeg godtar bare ting som jeg kan se bevis på."],
  ["Jeg har ingen problemer med å si unnskyld.", "Jeg kan være veldig krevende."],
  ["Jeg tåler ikke begrensninger/regler.", "Jeg kan ta egne, uavhengige avgjørelser."],
  ["I vanskelige diskusjoner kan jeg ta avgjørelsen.", "Når jeg blir bedt om å gjøre noe, gjør jeg mitt beste for å få det gjort."],
  ["Jeg kan lett/fort tilgi andre.", "Jeg nøler før jeg gjør eller sier noe."],
  ["Jeg unnskylder andre fort.", "Jeg klager over ting som har gått galt."],
  ["Jeg føyer meg fort til flertallets mening.", "Jeg er direkte og rett frem."],
  ["Jeg holder meg i bakgrunnen.", "Jeg kan godt snakke om meg selv."],
  ["I en gruppe tar jeg ofte initiativet.", "Jeg blir fort flau når ting ikke går som planlagt."],
  ["Jeg trøster gjerne andre.", "Jeg kan gi konstruktiv kritikk."],
  ["Jeg er enig med alle.", "Jeg kan irettesette irriterende eller påtrengende mennesker."],
  ["Jeg tar gjerne imot råd.", "Jeg tar hensyn til meg selv."],
  ["Jeg inngår klare avtaler.", "Jeg står imot når noe ikke er i tråd med reglene."],
  ["Jeg pleier å føle meg ansvarlig.", "Jeg kan være veldig streng når det er nødvendig."],
  ["Jeg er imøtekommende.", "Jeg er ikke redd for å være den jeg er."],
  ["Jeg sørger for at alle i teamet får ansvar for hver sin del av oppgaven.", "Jeg sier tydelig ifra hvis noe er urettferdig."],
  ["Jeg sørger for at alle får sine rettigheter.", "Jeg stiller høye krav til meg selv."],
  ["Jeg tar alltid over ansvaret.", "Jeg er kjenner mine egne ferdigheter."],
  ["Jeg er hjelpsom.", "Jeg kan instruere andre."],
  ["Jeg liker å samarbeide med andre.", "Jeg gir gjerne."],
  ["Jeg respekterer autoriteter.", "Jeg pleier å ha tillit til andre."],
  ["Jeg kan være selvkritisk.", "Jeg spør fort om hjelp."],
  ["Jeg er ikke lett å overtale.", "Jeg er utholdende - jeg gir meg ikke så lett."],
  ["Jeg liker å erte andre.", "Jeg liker å ha personlig frihet/Jeg liker ikke å bli begrenset."],
  ["Jeg kan godt stå opp og snakke i større grupper.", "Jeg tåler at det er åpenbare uenigheter."],
  ["Jeg unngår problemer.", "Jeg forklarer andre hvordan man bør gå frem."],
  ["Jeg tilpasser meg lett.", "Jeg tar hensyn til meg selv."],
  ["Jeg lar de andre være i fred hvis det oppstår vanskeligheter.", "Jeg setter min egen mening i bakgrunnen for å unngå uenigheter."],
  ["Jeg tar ingen ubetenksomme avgjørelser.", "Jeg holder meg til avtaler."],
  ["Jeg kan tilbakeholde noe for overordnede.", "Jeg nøler før jeg gjør eller sier noe."],
  ["Jeg måler meg gjerne opp mot andre.", "Jeg er ikke lett å overtale."],
  ["Jeg gjør mitt beste for å følge med på kjedelige forklaringer.", "Jeg forklarer andre hvordan de burde gå frem."],
  ["Jeg er i stand til å ta selvkritikk. Jeg er reflektert", "Jeg føler meg fort ansvarlig."],
  ["Jeg gjør motstand, når noe ikke er i tråd med reglene.", "Jeg tåler ikke at andre misliker meg."],
  ["Jeg kan være krevende overfor andre.", "Når jeg skal gjøre noe, gjør jeg mitt beste for å få det til."],
  ["Jeg har stor selvtillit.", "Jeg er beskjeden."],
  ["Jeg trekker meg lett tilbake ved konfliker.", "Når ting ikke går effektivt, organiserer jeg det heller selv."],
  ["Jeg står opp mot urettferdighet.", "Jeg er hjelpsom."],
  ["Jeg sier tydelig ifra hvis noe er urettferdig.", "Jeg får raskt tillit til andre mennesker."],
  ["Jeg streber etter å være best.", "Jeg beundrer sterke personligheter."],
  ["Jeg er ikke redd til å stille spørsmålstegn på allment aksepterte ting.", "Jeg forklarer gjerne."],
  ["Jeg lar meg ikke overkjøre.", "Jeg har en tendens til å ha medfølelse."],
  ["Jeg tar uavhengige avgjørelser.", "Jeg er vennlig mot alle."],
  ["I konflikter står jeg ved meningen min.", "Jeg inngår tydelige avtaler."],
  ["Jeg kan stå opp og snakke i større grupper.", "Jeg beskytter de svake."],
  ["Jeg er ikke redd for å fremstå slik jeg er.", "Jeg forklarer andre hva de burde gjøre."]
]
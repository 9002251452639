import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Button } from "react-bootstrap";
import { useGlobalState } from "../../contexts/GlobalStateContext";
import { useUserAuth } from "../context/UserAuthContext";

const Signup = () => {
  const { t } = useGlobalState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const { signUp } = useUserAuth();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      if (password === confirmPassword) {
        await signUp(name, email, password);
        navigate("/dashboard");
      } else {
        setError(t('Auth.alert_passwords_must_match'));
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <div className="p-5 box mt-2 text-center col-12 col-sm-10 col-md-8 col-lg-5">
        <h3 className="mb-4">{t('Auth.heading_signup')}</h3>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicDisplayName">
            <Form.Control
              type="text"
              placeholder={t('Auth.name')}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder={t('Auth.email')}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder={t('Auth.password')}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicConfirm">
            <Form.Control
              type="password"
              placeholder={t('Auth.confirm')}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Group>

          <div className="d-grid gap-2">
            <Button variant="primary" type="Submit">
              {t('Auth.sign_up')}
            </Button>
          </div>
        </Form>
      </div>
      <div className="p-4 box mt-3 text-center col-12 col-sm-10 col-md-8 col-lg-5">
        {t('Auth.already_have_an_account')} <Link to="/login">{t('Auth.log_in')}</Link>
      </div>
    </>
  );
};

export default Signup;
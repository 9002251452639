export const CHARACTERISTICS_COMM_EN = [
  ["I like to explain things.", "I like to help out."],
  ["I tend to be sympathetic.", "I'm always willing to compromise."],
  ["I am friendly towards everyone.", "I am conscientious."],
  ["I admire strong personalities.", "I withdraw easily when there is a conflict."],
  ["I am shy - timid.", "I am quick to dispute things."],
  ["I am not afraid to challenge or question things which are generally accepted.", "I do not let myself get pushed around."],
  ["In conflicts I stand up for my opinion.", "I always want to be the best."],
  ["I organize things myself when I see that they aren't run efficiently.", "I keep quiet about my opinions in order to avoid conflicts."],
  ["I protect people who are insecure.", "I stick to the agreements."],
  ["I think it is terrible when other people don't like me.", "I am modest."],
  ["I always do my best to follow along, even with boring explanations. ", "I only accept things that I can see evidence of."],
  ["I have no problem with apologizing.", "I can be very demanding."],
  ["I can't stand restrictions.", "I am able to make independent decisions — I am able to make my own decisions."],
  ["I can make the decision in difficult discussions.", "If I'm asked to do something, I do my best to get it done."],
  ["I can easily/quickly forgive others.", "I hesitate before I do or say something."],
  ["I excuse others quickly.", "I complain when things have gone wrong."],
  ["I quickly join the prevailing opinion.", "I am straightforward and direct."],
  ["I stay out of the spotlight.", "I am comfortable with talking about myself."],
  ["I often take the initiative in a group.", "I quickly become embarrassed when things don't go as planned."],
  ["I like to give other people comfort.", "I can give constructive criticism."],
  ["I agree with everyone.", "I can reprimand annoying or pushy people."],
  ["I receive advice gladly.", "I look after myself."],
  ["I make clear agreements.", "I resist when something goes against the rules."],
  ["I tend to feel responsible.", "I can be very strict when necessary."],
  ["I am accommodating.", "I am not afraid to present myself the way I am."],
  ["I make sure that everyone in the team has a part in the task at hand.", "I speak up clearly when things are unjust."],
  ["I make sure that everyone is treated fairly.", "I expect a lot from myself."],
  ["I always take responsibility.", "I know my own skills."],
  ["I am helpful.", "I can give instructions to others."],
  ["I like working together with others.", "I enjoy giving."],
  ["I respect authority.", "I tend to trust others."],
  ["I am able critique myself.", "I am quick to ask for help."],
  ["I am not easy to convince.", "I am reluctant - I don't easily /\"go for/\" things."],
  ["I like to tease others.", "I don't like to be /\"kept on a short leash/\". / I love my independence."],
  ["I can easily stand up and speak in front of large groups.", "I can cope with open differences of opinion."],
  ["I avoid difficulties.", "I tell others how they should proceed."],
  ["I adjust easily.", "I take care of myself."],
  ["I let others be if there are difficulties.", "I keep quiet about my personal opinions in order to avoid arguments."],
  ["I don't make rash decisions.", "I keep to agreements."],
  ["I am able to withhold things from my superiors.", "I hesitate before I say or do anything."],
  ["I like to measure myself against others.", "I am not easy to convince."],
  ["I do my best to follow along with boring explanations.", "I tell others how they should proceed."],
  ["I am capable of self-criticism.", "I quickly feel responsible."],
  ["I resist when something goes against the rules.", "I can't stand it if others don't like me."],
  ["I can be very demanding of others.", "If I'm going to do something, I do my best to carry it through."],
  ["I have a lot of self confidence.", "I am shy."],
  ["I withdraw easily when there is a conflict.", "I organize things myself when I see that they aren't run efficiently."],
  ["I stand up to injustice.", "I am helpful."],
  ["I speak up clearly when things are unjust.", "I trust other people quickly."],
  ["I strive to be the best.", "I admire strong personalities."],
  ["I am not afraid to question things which are generally accepted.", "I like to explain."],
  ["I do not let myself get pushed around.", "I tend to be sympathetic."],
  ["I make decisions independently.", "I am friendly towards everyone."],
  ["I stand by my opinion when there are conflicts.", "I make clear agreements."],
  ["I can easily stand up and speak in front of larger groups.", "I protect the weak."],
  ["I am not afraid to present myself as I am.", "I explain to others how they should act."]
]
import React from "react"
import { isBrowser } from 'react-device-detect';

const Footer = ({ activeComponent }) => {
    return (
        <footer id='footer' className="position-relative footer mt-auto m-4" style={isBrowser || (activeComponent === 'Home' || activeComponent === 'Introduction' || activeComponent === 'Certificates') ? { visibility: 'visible' } : { visibility: 'hidden' }}>
            <span id='poweredByAT'>Copyright 2022 © <b>BCC</b></span>
            <img src={process.env.PUBLIC_URL + '/images/bcc/logo.svg'} alt='Assessment-Center' height='24' className='float-end' />
        </footer>
    )
}

export default Footer

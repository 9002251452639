import React, { useState, useEffect } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { Alert } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import Pdf from '@mikecousins/react-pdf'

const Introduction = () => {
    const { t, setActiveComponent, activePage, setActivePage } = useGlobalState();
    const [error, setError] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        document.body.style.background = '#fafafa';
        document.getElementById('navbar').style.backgroundColor = 'var(--primary-color)';
        Object.assign(document.getElementById('main-container-assessment').style, { backgroundColor: '#fff', borderColor: '#dfdfdf' })
    }, []);

    const onClickHandler = () => {
        if (activePage === '2' && !document.getElementById('checkbox').checked) {
            setError(t('Introduction.alert_data_storage'));
        } else if (activePage === '4') {
            setActiveComponent('Communication');
            setActivePage('Introduction');
        } else {
            setError('');
            setActivePage(String(Number(activePage) + 1));
        }
    }

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const renderIntroductionPage = () => {
        if (activePage === '1') {
            return (
                <>
                    <h2 className='mb-3'>{t("Introduction.page_1_heading")}</h2>
                    <p className='text-intro my-3'>{t("Introduction.page_1_1")}</p>
                    <p className='text-intro my-3'>{t("Introduction.page_1_2")}</p>
                    <p className='text-intro my-3'>{t("Introduction.page_1_3")}</p>
                </>
            )
        } else if (activePage === '2') {
            return (
                <>
                    <h3 className='mb-3'>{t("Introduction.page_2_heading")}</h3>
                    <p className='text-intro my-3'>{t("Introduction.page_2_1")}</p>
                    <p className='text-intro my-3'>{t("Introduction.page_2_2")}</p>
                    <button className="btn btn-secondary my-1" onClick={showModal}>{t('Introduction.data_protection_info')}</button>
                    <p className='text-intro my-3'><label><input id='checkbox' type='checkbox' />{t("Introduction.page_2_3")}</label></p>
                    {error && <Alert variant="danger">{error}</Alert>}
                </>
            )
        } else if (activePage === '3') {
            return (
                <>
                    <h3 className='mb-3'>{t("Introduction.notes_heading")}</h3>
                    <p className='text-intro my-3'>{t("Introduction.page_3_1")}</p>
                    <p className='text-intro my-3'>{t("Introduction.page_3_2")}</p>
                    <p className='text-intro my-3'>{t("Introduction.page_3_3")}<br />{t("Introduction.page_3_4")}</p>
                </>
            )
        } else if (activePage === '4') {
            return (
                <>
                    <h3 className='mb-3'>{t("Introduction.notes_heading")}</h3>
                    <p className='text-intro my-3'>{t("Introduction.page_4_1")}<b>{t("Introduction.page_4_2")}</b></p>
                    <p className='text-intro my-3'>{t("Introduction.page_4_3")}<b>{t("Introduction.page_4_4")}</b></p>
                    <p className='text-intro my-3'>{t("Introduction.page_4_5")}</p>
                    <p className='text-intro my-3'>{t("Introduction.page_4_6")}</p>
                </>
            )
        }
    }


    const PdfViewer = () => {
        return (
            <Pdf file={process.env.PUBLIC_URL + "/pdf/MAL_Taushetserklæring_V3.pdf"} page={1} scale={window.innerWidth < 420 ? 0.64 : 1.4}>
                {({ pdfDocument, canvas }) => (
                    <>
                        {!pdfDocument && <span>Loading...</span>}
                        {canvas}
                    </>
                )}
            </Pdf>
        )
    };

    return (
        <div className='col-md-10 col-xl-9 p-3'>
            {renderIntroductionPage()}
            <div className='text-intro my-2 text-center' style={{ lineHeight: '40px', paddingTop: '.8rem', color: '#A9A9A9', fontSize: '14px' }}>
                <button className='btn btn-secondary' style={{ float: 'left', visibility: activePage === '1' ? "hidden" : "visible" }} onClick={() => setActivePage(String(Number(activePage) - 1))}>{t("App.btn_back")}</button>
                <span className='page-text'>{t("Introduction.introduction")} - {t("App.page")} {activePage} / 4</span>
                <button className='btn btn-primary' style={{ float: 'right' }} onClick={onClickHandler}>{t("App.btn_next")}</button>
            </div>
            <Modal show={isOpen} onHide={hideModal}>
                <Modal.Header style={{ justifyContent: 'end' }}>
                    <button className='btn btn-secondary' onClick={hideModal}>X</button>
                </Modal.Header>
                <Modal.Body className='p-0' style={{ overflowY: 'auto' }}>
                    <PdfViewer />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Introduction

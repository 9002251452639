import React, { createContext, useContext, useState, useEffect, useCallback } from "react"
import { useGlobalState } from "./GlobalStateContext"
import { ProgressBar } from 'react-bootstrap'
import { Cookies } from 'react-cookie'
import lifecycle from 'page-lifecycle'

const assessmentContext = createContext();

export function AssessmentContext({ children }) {
  const { t, setActivePage, currentProgress, setCurrentProgress, setUserData } = useGlobalState()
  const [activeAssessment, setActiveAssessment] = useState(currentProgress != null ? currentProgress : 0)
  const [userSelectedBtns, setUserSelectedBtns] = useState([])
  const [btnsDisabled, setBtnsDisabled] = useState(false)
  const [cookieDataSet, setCookieDataSet] = useState(false)

  useEffect(() => {
    const cookies = new Cookies()
    if (!cookieDataSet && cookies.get('selectedBtns')) {
      cookies.get('selectedBtns') && setUserSelectedBtns(cookies.get('selectedBtns'));
      setCookieDataSet(true)
    }
  }, [cookieDataSet, setUserData])

  useEffect(() => {
    const onVisibilityChange = (e) => {
      e.preventDefault()
      e.returnValue = 'return something'
      const cookies = new Cookies()
      userSelectedBtns.length > 0 && cookies.set('selectedBtns', userSelectedBtns, { path: '/', expires: new Date(Date.now() + 604800), secure: true, sameSite: 'strict' })
    }
    lifecycle.addEventListener("statechange", onVisibilityChange)
    return () => lifecycle.removeEventListener("statechange", onVisibilityChange)
  }, [userSelectedBtns])

  function checkProgress(changeBtnStyleOnClick, lengthAssessment) {
    if (activeAssessment < currentProgress) {
      let current_assessment = userSelectedBtns.find(obj => { return obj.id === activeAssessment })
      changeBtnStyleOnClick(current_assessment.value);
    }

    if (currentProgress === lengthAssessment && currentProgress === activeAssessment) {
      setActivePage('Complete')
    }
  }

  const handleBtnOnClick = useCallback((currentTarget, changeBtnStyleOnClick) => {
    setBtnsDisabled(true);
    changeBtnStyleOnClick(currentTarget.value);

    const timer = setTimeout(() => {
      if (userSelectedBtns) {
        let newArr = [...userSelectedBtns];
        newArr[activeAssessment] = { id: activeAssessment, value: currentTarget.value };
        setUserSelectedBtns(newArr);
      }

      if (currentProgress === activeAssessment) {
        let newProgress = (activeAssessment + 1);
        setCurrentProgress(newProgress);
      }
      setActiveAssessment(activeAssessment + 1);
      setBtnsDisabled(false);
    }, 250);
    return () => clearTimeout(timer);
  }, [activeAssessment, currentProgress, setCurrentProgress, userSelectedBtns])

  function handleBtnBackOnClick() {
    setActivePage('Assessment');
    setActiveAssessment(activeAssessment - 1);
  }

  function resetStates() {
    setUserSelectedBtns([]);
    setActiveAssessment(0);
    setCurrentProgress(0);
  }

  const renderAssessmentForm = (renderAssessmentBtn, lengthAssessment, strAssessment) => {
    if (activeAssessment < lengthAssessment) {
      return (<>
        <div className='assessment-container col-sm-10 col-md-9 col-lg-8 col-xl-7' key={'div' + strAssessment + activeAssessment}>
          <h4 className='assessment-heading'>{t(strAssessment + ".heading")}</h4>
          {renderAssessmentBtn(1)}
          {renderAssessmentBtn(2)}
        </div>
        <div className='footer-assessment d-flex justify-content-between col-sm-10 col-md-9 col-lg-8 col-xl-7'>
          <button className='btn btn-footer-assessment' id='btn-footer-back' style={{ visibility: activeAssessment === 0 ? "hidden" : "visible" }} onClick={() => handleBtnBackOnClick()}>{t("App.btn_back")}</button>
          <span className='page-text'>Assessment {t(strAssessment + '.name')} - <b>{activeAssessment + 1} / {lengthAssessment}</b></span>
          <button className='btn btn-footer-assessment' id='btn-footer-next' style={{ visibility: activeAssessment >= currentProgress ? "hidden" : "visible" }} onClick={() => setActiveAssessment(activeAssessment + 1)}>{t("App.btn_next")}</button>
        </div>
        <ProgressBar animated striped now={currentProgress} max={lengthAssessment} label={`${currentProgress} von ` + lengthAssessment} />
      </>)
    }
  }

  return (
    <assessmentContext.Provider
      value={{ activeAssessment, userSelectedBtns, btnsDisabled, cookieDataSet, setBtnsDisabled, checkProgress, handleBtnOnClick, handleBtnBackOnClick, renderAssessmentForm, resetStates }}
    >
      {children}
    </assessmentContext.Provider>
  )
}

export function useAssessment() {
  return useContext(assessmentContext)
}
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_NO } from "./no/translation";
import { TRANSLATIONS_EN } from "./en/translation";
import { TRANSLATIONS_DE } from "./de/translation";

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'no',
    resources: {
      de: {
        translation: TRANSLATIONS_DE
      },
      no: {
        translation: TRANSLATIONS_NO
      },
      en: {
        translation: TRANSLATIONS_EN
      }
    }
  });

export default i18n;
import React, { useState, useEffect, useCallback } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { Form, Button, Alert } from "react-bootstrap"
import { Cookies } from 'react-cookie'
import axios from 'axios'

const Home = () => {
    const { t, API_PATH, language, handleLanguageChange, setActiveComponent, setUserData } = useGlobalState();
    const [firstname, setFirstname] = useState(false);
    const [lastname, setLastname] = useState(false);
    const [error, setError] = useState(false);

    const setThemeHome = useCallback(() => {
        document.body.style.background = "url('images/bcc/background.jpg') center 44% / cover no-repeat";
        document.getElementById('navbar').style.backgroundColor = 'transparent';
        document.getElementById('main-container-assessment').style.backgroundColor = 'transparent';
        document.getElementById('main-container-assessment').style.borderColor = 'transparent';
        document.getElementById('poweredByAT').style.paddingLeft = '0'
    }, []);

    useEffect(() => {
        const cookies = new Cookies()
        if (Object.keys(cookies).length === 0 || cookies.get("component") === 'Home') {
            setThemeHome()
        }
    }, [setThemeHome]);

    const createParticipantInDB = useCallback(() => {
        axios.post(API_PATH + 'participant.php', { firstname: firstname, lastname: lastname })
            .then(res => { setUserData({ participant_id: res.data.participant_id, firstname: firstname, lastname: lastname }) })
            .catch(e => { setError(e.message) })
    }, [API_PATH, setUserData, firstname, lastname]);


    const handleSubmit = (e) => {
        e.preventDefault();
        createParticipantInDB();
        setActiveComponent("Introduction");
    }

    return (<>
        <div className='col-lg-8 mb-5 p-4 box text-center d-flex flex-column justify-content-center' style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
            <h4>{t("App.welcome")}!</h4>
            <h2 id='textWelcome'>{t('Home.find_your_strengths')}</h2>
            <Form onSubmit={handleSubmit} autoComplete='off'>
                {error ? <Alert variant="danger">{error}</Alert> : false}
                <Form.Group controlId="formLanguage" className='mt-3 d-flex flex-row justify-content-center'>
                    <Form.Select name='language' aria-label="language" className='text-align-center' value={language} onChange={e => { handleLanguageChange(e.target.value) }} style={{ maxWidth: '21.7rem', background: 'url(' + process.env.PUBLIC_URL + '/images/' + language + '/' + language + '.png) #fff 97%/26px no-repeat' }}>
                        <option value="no">Norsk</option>
                        <option value="en">English</option>
                        <option value="de">Deutsch</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group controlId="formBasicName" className='mt-2 d-flex flex-row justify-content-center'>
                    <Form.Control className='m-2' style={{ width: '12rem', maxWidth: '40%' }} type="text" required placeholder={t('Home.firstname')} onChange={(e) => setFirstname(e.target.value)} />
                    <Form.Control className='m-2' style={{ width: '12rem', maxWidth: '40%' }} type="text" required placeholder={t('Home.lastname')} onChange={(e) => setLastname(e.target.value)} />
                </Form.Group>
                <Form.Group className='m-4'>
                    <Button className='btn-start' variant="primary" type="Submit">{t('App.btn_start')}</Button>
                </Form.Group>
            </Form>
        </div>
    </>)
}

export default Home

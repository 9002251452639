import React, { useState, useEffect, useCallback } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { Form, Alert, Button } from "react-bootstrap"
import axios from 'axios'

const MailConfiguration = () => {
    const { t, API_PATH } = useGlobalState()
    const [mailFrom, setMailFrom] = useState('')
    const [mailRecipient, setMailRecipient] = useState('')
    const [mailCC, setMailCC] = useState('')
    const [mailBCC, setMailBCC] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        axios.post(API_PATH + 'mail-config.php', { action: 'read' })
            .then(res => {
                setMailFrom(res.data.configFrom)
                setMailRecipient(res.data.configRecipient)
                setMailCC(res.data.configCC)
                setMailBCC(res.data.configBCC)
            })
            .catch(e => { setError(e.message) })
    }, [API_PATH])

    const updateEmailTemplate = useCallback(() => {
        axios.post(API_PATH + 'mail-config.php', { action: 'update', mailRecipient: mailRecipient, mailCC: mailCC, mailBCC: mailBCC, mailFrom: mailFrom })
            .then(() => {
                alert('Email template was updated successfully!')
            })
            .catch(e => { setError(e.message) })
    }, [API_PATH, mailBCC, mailCC, mailRecipient, mailFrom])

    const handleSubmit = (e) => {
        e.preventDefault()
        updateEmailTemplate()
    }

    return (
        <>
            <div className="p-5 box mt-2 col-12 col-sm-10 col-md-8 col-lg-5">
                <h3 className="mb-4">{t('Auth.heading_mail_config')}</h3>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit} autoComplete="off">
                    <Form.Group className="mb-3" controlId="formFrom">
                        <Form.Label><b>E-post sender</b></Form.Label>
                        <Form.Control type="email" defaultValue={mailFrom} onChange={(e) => setMailFrom(e.target.value)} required />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formRecipient">
                        <Form.Label><b>E-post mottaker</b></Form.Label>
                        <Form.Control type="email" defaultValue={mailRecipient} onChange={(e) => setMailRecipient(e.target.value)} required />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formCC">
                        <Form.Label><b>E-post CC</b></Form.Label>
                        <Form.Control type="email" defaultValue={mailCC} onChange={(e) => setMailCC(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBCC">
                        <Form.Label><b>E-post BCC (Blindkopi)</b></Form.Label>
                        <Form.Control type="email" defaultValue={mailBCC} onChange={(e) => setMailBCC(e.target.value)} />
                    </Form.Group>

                    <div className="d-grid gap-2">
                        <Button variant="primary" type="Submit">Endre</Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default MailConfiguration
import React, { createContext, useContext, useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Cookies } from 'react-cookie'
import lifecycle from 'page-lifecycle'
import i18n from "../translations/i18n"
import "../translations/i18n"

const globalStateContext = createContext();

export function GlobalStateContext({ children }) {
  const { t } = useTranslation();
  const [API_PATH] = useState('https://ac.workn.travel/v3/api/')
  const [globalError, setGlobalError] = useState(false)
  const [activeComponent, setActiveComponent] = useState('Home');
  const [activePage, setActivePage] = useState('1');
  const [currentProgress, setCurrentProgress] = useState(0)
  const [fillTime, setFillTime] = useState(0)
  const [language, setLanguage] = useState('no')
  const [cookieDataSet, setCookieDataSet] = useState(false)
  const [userData, setUserData] = useState({ firstname: '', lastname: '' });
  const [valuesComm, setValuesComm] = useState({});
  const [valuesThink, setValuesThink] = useState({});

  useEffect(() => {
    const cookies = new Cookies()
    if (!cookieDataSet && Object.keys(cookies).length > 0) {
      cookies.get("component") && setActiveComponent(cookies.get("component"));
      cookies.get("page") && setActivePage(cookies.get("page"));
      cookies.get("progress") && setCurrentProgress(Number(cookies.get("progress")));
      cookies.get("filltime") && setFillTime(Number(cookies.get("filltime")));
      cookies.get("language") && handleLanguageChange(cookies.get("language"));
      cookies.get("userData") && setUserData(cookies.get("userData"));
      setCookieDataSet(true)
    }
  }, [cookieDataSet])

  useEffect(() => {
    const onVisibilityChange = (e) => {
      e.preventDefault()
      e.returnValue = 'return something'
      const cookies = new Cookies()
      cookies.set('component', activeComponent, { path: '/', expires: new Date(Date.now() + 604800), secure: true, sameSite: 'strict' })
      cookies.set('page', activePage, { path: '/', expires: new Date(Date.now() + 604800), secure: true, sameSite: 'strict' })
      fillTime != null && cookies.set('filltime', Number(fillTime), { path: '/', expires: new Date(Date.now() + 604800), secure: true, sameSite: 'strict' })
      currentProgress != null && cookies.set('progress', Number(currentProgress), { path: '/', expires: new Date(Date.now() + 604800), secure: true, sameSite: 'strict' })
      cookies.set('language', language, { path: '/', expires: new Date(Date.now() + 604800), secure: true, sameSite: 'strict' })
      cookies.set('userData', JSON.stringify(userData), { path: '/', expires: new Date(Date.now() + 604800), secure: true, sameSite: 'strict' })
    }
    lifecycle.addEventListener("statechange", onVisibilityChange)
    return () => lifecycle.removeEventListener("statechange", onVisibilityChange)
  }, [activeComponent, activePage, currentProgress, fillTime, language, userData])

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  }

  const updateFillTime = useCallback(() => {
    if (activePage === 'Assessment') {
      const interval = setTimeout(() => setFillTime(fillTime + 1000), 1000)
      return () => clearTimeout(interval)
    } else if (activePage === 'Introduction') {
      setFillTime(0)
    }
  }, [fillTime, setFillTime, activePage])

  function setStyleInternal() {
    document.body.style.background = '#fff';
    document.getElementById('navbar').style.backgroundColor = 'var(--primary-color)';
    Object.assign(document.getElementById('main-container').style, { backgroundColor: '#fff', maxWidth: '2030px' })
    document.getElementById('nav-container').style.width = '1320px';
    document.getElementById('poweredByAT').style.paddingLeft = '240px';
  }

  return (
    <globalStateContext.Provider
      value={{ t, API_PATH, globalError, setGlobalError, activeComponent, setActiveComponent, activePage, setActivePage, currentProgress, setCurrentProgress, fillTime, setFillTime, updateFillTime, language, handleLanguageChange, userData, setUserData, valuesComm, setValuesComm, valuesThink, setValuesThink, setStyleInternal }}
    >
      {children}
    </globalStateContext.Provider>
  );
}

export function useGlobalState() {
  return useContext(globalStateContext);
}
import React, { useEffect } from "react"
import { useGlobalState } from "../../contexts/GlobalStateContext"
import { useUserAuth } from "../context/UserAuthContext"

const Dashboard = () => {
  const { t, setStyleInternal } = useGlobalState();
  const { user } = useUserAuth();

  useEffect(() => {
    setStyleInternal();
  }, [setStyleInternal]);

  return (<>
    <div className="mt-4 p-3 text-center">
      <h5>{t("Internal.heading")}</h5>
      <h4>{t("App.welcome")}, {user && user.displayName}!</h4>
    </div>
  </>)
}

export default Dashboard
import React, { useState, useEffect, useCallback } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { useAssessment } from "../contexts/AssessmentContext"
import { Alert } from "react-bootstrap"
import { Cookies } from 'react-cookie'
import axios from 'axios'
import { CHARACTERISTICS_COMM_NO } from '../translations/no/characteristics_comm.js'
import { CHARACTERISTICS_COMM_DE } from '../translations/de/characteristics_comm.js'
import { CHARACTERISTICS_COMM_EN } from '../translations/en/characteristics_comm.js'

const Communication = () => {
    const { t, API_PATH, userData, setActiveComponent, activePage, setActivePage, fillTime, updateFillTime, setValuesComm } = useGlobalState();
    const { userSelectedBtns, activeAssessment, btnsDisabled, checkProgress, handleBtnOnClick, handleBtnBackOnClick, renderAssessmentForm, resetStates } = useAssessment();
    const [arrUserSelectedBtns, setArrUserSelectedBtns] = useState(Array.from({ length: 8 }, () => new Array(8).fill(null)));
    const lengthAssessment = CHARACTERISTICS_COMM_DE.length;
    const [error, setError] = useState();

    useEffect(() => {
        updateFillTime()
    }, [updateFillTime, fillTime])

    // Calculation of the Assessment Communication Values (don't change anything here)
    const storeUserSelectValues = useCallback((a1, a2, index_start, index_end) => {
        try {
            let user_selected_btns = [...arrUserSelectedBtns];
            let newValue = 1;

            for (let i = index_start; i < index_end; i++) {
                if (a1 >= 8) {
                    newValue++;
                    a1 = newValue;
                    a2 = 0;
                } else if (a2 >= 8) {
                    newValue++;
                    a1 = 0;
                    a2 = newValue;
                }

                if (i === 28) {
                    newValue = 1;
                }

                if (userSelectedBtns[i].value === '1') {
                    user_selected_btns[a1][a2] = 1;
                } else if (userSelectedBtns[i].value === '2') {
                    user_selected_btns[a1][a2] = 2;
                }

                a1++;
                a2++;
            }
            setArrUserSelectedBtns(user_selected_btns);
        } catch (e) {
            setError(e.message)
        }
    }, [userSelectedBtns, arrUserSelectedBtns])

    const setCommunicationValues = useCallback(() => {
        try {
            let values_comm = new Array(8).fill(0);
            for (let row = 0; row < 8; row++) {
                for (let col = 0; col < 8; col++) {
                    if (arrUserSelectedBtns[row][col] === 1) {
                        values_comm[row]++;
                    }
                }
            }

            for (let col = 0; col < 8; col++) {
                for (let row = 0; row < 8; row++) {
                    if (arrUserSelectedBtns[row][col] === 2) {
                        values_comm[col]++;
                    }
                }
            }

            const keys = ['value_a', 'value_b', 'value_c', 'value_d', 'value_e', 'value_f', 'value_g', 'value_h'];
            let commValuesNew = {};
            for (let i = 0; i < keys.length; i++) {
                commValuesNew[keys[i]] = values_comm[i]
            }
            commValuesNew['fillTime'] = fillTime;
            setValuesComm(commValuesNew);
            axios.post(API_PATH + 'store-assessment-values-in-database.php', { userData: userData, valuesComm: commValuesNew })
                .catch(e => { setError(e.message) })
            const cookies = new Cookies()
            cookies.set('valuesComm', commValuesNew, { path: '/', expires: new Date(Date.now() + 604800), secure: true, sameSite: 'strict' })
        } catch (e) {
            setError(e.message)
        }
    }, [API_PATH, arrUserSelectedBtns, setValuesComm, fillTime, userData])

    const changeBtnStyleOnClick = useCallback((value_current_assessment) => {
        if (document.getElementById('field_' + value_current_assessment) && document.getElementById('characteristic_' + value_current_assessment)) {
            document.getElementById('field_' + value_current_assessment).style.backgroundColor = "var(--primary-color)";
            document.getElementById('characteristic_' + value_current_assessment).style.color = "white";
        }
    }, [])

    useEffect(() => {
        checkProgress(changeBtnStyleOnClick, lengthAssessment)
    }, [checkProgress, changeBtnStyleOnClick, lengthAssessment])

    const handleComplete = () => {
        storeUserSelectValues(0, 1, 0, 28);
        storeUserSelectValues(1, 0, 28, 56);
        setCommunicationValues();
        setActiveComponent("Certificates");
        setActivePage(false);
        resetStates();
    }

    const renderAssessmentBtn = (id) => {
        const CHARACTERISTICS_LOCAL = t('App.lang') === 'en' ? CHARACTERISTICS_COMM_EN : (t('App.lang') === 'de' ? CHARACTERISTICS_COMM_DE : CHARACTERISTICS_COMM_NO)
        return (
            <button className="btn btn-assessment" id={'field_' + id} key={'field_' + id + activeAssessment} value={id} disabled={btnsDisabled} onClick={(e) => { handleBtnOnClick(e.currentTarget, changeBtnStyleOnClick) }}>
                <h5 id={'characteristic_' + id}>{id === 1 ? CHARACTERISTICS_LOCAL[activeAssessment][0] : (id === 2 ? CHARACTERISTICS_LOCAL[activeAssessment][1] : false)}</h5>
            </button>
        )
    }

    const renderAssessmentCommunication = () => {
        if (activePage === 'Introduction') {
            return (
                <div className='col-md-10 col-xl-9 p-3 d-flex flex-column align-items-center'>
                    <h4 className='mb-3'>Assessment - {t("Communication.name")}</h4>
                    <p>{t("Communication.introduction_1")}</p>
                    <p>{t("Communication.introduction_2")}</p>
                    <p>{t("Communication.introduction_3")}</p>
                    <button className='btn btn-primary m-2' onClick={() => setActivePage('Assessment')}>{t("App.btn_start")}</button>
                </div>
            )
        } else if (activePage === 'Complete') {
            return (
                <div className='col-md-10 col-xl-9 p-3'>
                    <h4 className='mb-3'>{t("Communication.completed_heading")}</h4>
                    <Alert variant="success">{t("Communication.completed")}</Alert>
                    <button className='btn btn-secondary my-2 mx-0 float-start' style={{ borderColor: 'transparent', background: '#fff' }} onClick={() => handleBtnBackOnClick()}>{t("App.btn_back")}</button>
                    <button className='btn btn-primary m-2 float-end' onClick={() => handleComplete()}>{t("App.btn_next")}</button>
                </div>
            )
        } else if (activePage === 'Assessment') {
            return (<> {renderAssessmentForm(renderAssessmentBtn, lengthAssessment, 'Communication')} </>)
        }
    }

    return (error ? <Alert className='mb-2' variant="danger">{error}</Alert> : renderAssessmentCommunication())
}

export default Communication

import React, { useState, useEffect, useCallback } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { useCert } from "../contexts/CertificatesContext"
import { Form, Table, Button, Alert } from "react-bootstrap"
import axios from 'axios'
import CertificateCommunication from './CertificateCommunication'

const Recovery = () => {
    const { t, API_PATH, setStyleInternal, setUserData, setValuesComm, valuesComm } = useGlobalState()
    const { generateAssessments, error, setError } = useCert()
    const [name, setName] = useState("")
    const [userlist, setUserlist] = useState([])
    const [valuesCert, setValuesCert] = useState({})
    const [areValuesSet, setAreValuesSet] = useState(false)
    const [completed, setCompleted] = useState(false)

    useEffect(() => {
        setStyleInternal()
    }, [setStyleInternal])

    const readParticipantFromDB = useCallback((name) => {
        axios.post(API_PATH + 'read-certs-recovery.php', { name: name })
            .then(res => {
                if (!areValuesSet) {
                    if (res.data.name) {
                        if (res.data.id_comm) {
                            setValuesCert({ date: res.data.date, fillTimeComm: res.data.fillTimeComm })
                            setUserData({ name: res.data.name, firstname: res.data.firstname, lastname: res.data.lastname })
                            setValuesComm({ value_a: res.data.value_a, value_b: res.data.value_b, value_c: res.data.value_c, value_d: res.data.value_d, value_e: res.data.value_e, value_f: res.data.value_f, value_g: res.data.value_g, value_h: res.data.value_h, fillTime: res.data.fillTimeComm })
                            setAreValuesSet(true)
                        } else {
                            setUserlist([])
                            setError(t('Certificates.error_processing'))
                        }
                    } else {
                        if (res.data.length > 0) {
                            setUserlist(res.data)
                            setError('')
                        } else {
                            setUserlist([])
                            setError(t('Recovery.no_results') + name + '.')
                        }
                    }
                }
            })
            .catch(e => { setError(e.message) })
    }, [t, API_PATH, areValuesSet, setUserData, setValuesComm, setError])

    const handleRecoverCert = async (generatePDF) => {
        document.getElementById('container-recover').style.transform = "scale(1)"
        await generateAssessments(generatePDF)
        document.getElementById('container-recover').style.transform = "scale(0.75)"
        setCompleted(true)
    }

    const openCertFromUserlist = (name) => {
        setName(name)
        readParticipantFromDB(name)
    }

    const Userlist = () => {
        let content = []
        for (let i in userlist) {
            content.push(
                <tr key={'trUserlist' + i}>
                    <td><Button variant="primary" onClick={() => openCertFromUserlist(userlist[i].name)}>{userlist[i].name}</Button></td>
                </tr>
            )
        }
        return content
    }

    return (
        <div className='loading mt-3 p-3'>
            {error && <Alert className='mb-2' variant="danger">{error}</Alert>}
            {areValuesSet ?
                Object.keys(valuesComm).length > 0 && 
                <div id='container-recover'>
                    <div className='print-hide d-flex justify-content-center'>
                        {completed && <Alert className='mb-4' style={{ fontSize: '1.25rem', lineHeight: '2' }} variant="success">{t("Recovery.alert_certificates_saved")}</Alert>}
                    </div>
                    <div className='m-2 print-hide d-flex flex-row justify-content-center'>
                        <Button variant="primary" className='mx-2' style={{ fontSize: '1.25rem', lineHeight: '1.6' }} onClick={() => handleRecoverCert(false)}>{t("Recovery.btn_save_in_archive")}</Button>
                        <Button variant="primary" className='mx-2' style={{ fontSize: '1.25rem', lineHeight: '1.6' }} onClick={() => handleRecoverCert(true)}>{t("Recovery.btn_download_pdf")}</Button>
                        <Button variant="primary" className='mx-2' style={{ fontSize: '1.25rem', lineHeight: '1.6' }} onClick={() => window.print()}>{t("Recovery.btn_print")}</Button>
                    </div>
                    <div id='cert-container' className='d-flex mt-5 loading opacity-100'>
                        <CertificateCommunication date={valuesCert.date} fillTime={valuesCert.fillTimeComm} />
                    </div>
                </div>
                : <>
                    <h4 className='m-4 print-hide'>{t("Recovery.heading")}</h4>
                    <Form autoComplete="off" onSubmit={() => readParticipantFromDB(name)} className='mb-4 print-hide d-flex justify-content-center'>
                        <Form.Group className='mx-2' controlId="formBasicDisplayName">
                            <Form.Control type="text" placeholder={t('Auth.name')} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" onClick={() => readParticipantFromDB(name)}>{t('Internal.search')}</Button>
                    </Form>
                    {userlist.length > 0 &&
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>{t('Internal.show')} {userlist.length} {t('Recovery.results_for')} "{name}":</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Userlist />
                            </tbody>
                        </Table>
                    }
                </>
            }
        </div >
    )
}

export default Recovery
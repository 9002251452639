export const TRANSLATIONS_DE = {
    App: {
        lang: 'de',
        title: 'Assessment-Center',
        welcome: 'Herzlich Willkommen',
        page: 'Seite',
        btn_start: 'Starten',
        btn_back: 'Zurück',
        btn_next: 'Weiter',
        btn_previous_page: 'Vorherige Seite',
        btn_next_page: 'Nächste Seite',
        cancel: 'Abbrechen',
        loading: 'Lädt...'
    },
    Nav: {
        dashboard: 'Interner Bereich',
        certificates: 'Urkundenarchiv',
        recovery: 'Urkunden wiederherstellen',
        config: 'Konfiguration',
        account_settings: 'Kontoeinstellungen',
        log_out: 'Abmelden'
    },
    Auth: {
        heading_login: 'Mitarbeiter-Login',
        heading_signup: 'Neues Mitarbeiter-Konto',
        heading_mail_config: 'E-Mail-Konfiguration',
        name: 'Name',
        email: 'E-Mail-Adresse',
        password: 'Passwort',
        confirm: 'Passwort bestätigen',
        alert_passwords_must_match: "Die Passwörter müssen übereinstimmen!",
        log_in: "Anmelden",
        log_out: "Abmelden",
        sign_up: "Registrieren",
        reset_password: 'Passwort zurücksetzen',
        dont_have_an_account: "Noch kein Konto?",
        already_have_an_account: "Haben Sie ein Konto?",
        forgot_password: "Passwort vergessen?"
    },
    Home: {
        firstname: 'Vorname',
        lastname: 'Nachname',
        find_your_strengths: "Finde Deine Stärken!",
    },
    Introduction: {
        introduction: 'Einführung',
        page_1_heading: 'Herzlich Willkommen',
        page_1_1: 'Die nächsten Seiten machen Sie mit der Bedienung des Assessment-Centers vertraut.',
        page_1_2: 'Bitte beachten Sie: Das Assessment sollte erst ab einem Mindestalter von 15 Jahren durchgeführt werden.',
        page_1_3: 'Diese Befragung verfolgt das Ziel, Ihnen eine differenzierte Rückmeldung zu Ihren Präferenzen im Alltag zu geben. Die folgenden Fragebögen umfassen daher Aussagen zu Ihrem persönlichen Kommunikationsverhalten und Denkweisen in verschiedenen erfolgsrelevanten Situationen.',
        page_2_heading: 'Zustimmung zur Datenverarbeitung',
        page_2_1: 'Bevor Sie mit dem Assessment beginnen, informieren wir Sie darüber, wie wir Ihre Daten verarbeiten und wie Sie Ihre Einwilligung in die Datenverarbeitung geben.',
        page_2_2: 'Bitte lesen Sie dazu die Informationen (Wichtig: Bitte in jedem Falle lesen). Setzen Sie dann unten den Haken, um Ihre Zustimmung zu geben, klicken Sie danach auf den Button "Weiter".',
        page_2_3: ' Ich stimme der Speicherung meiner personenbezogenen Daten zu. Alle persönlichen Informationen werden vertraulich und mit größter Sorgfalt behandelt. Die Daten werden nur dem Personalwesen zugänglich gemacht.',
        data_protection_info: 'Datenschutzinformationen lesen',
        alert_data_storage: "Bitte markieren Sie das Kästchen oben, um der Speicherung Ihrer personenbezogenen Daten zuzustimmen.",
        notes_heading: 'Hinweise',
        page_3_1: 'Bitte stufen Sie die Aussagen so ein, wie es für Sie persönlich am ehesten zutrifft.',
        page_3_2: 'Es gibt weder richtige, noch falsche Antworten. Nur bei einer ehrlichen Bearbeitung kann sichergestellt werden, dass Ihre Ergebnisse in sich logisch und stimmig sind. Außerdem ist dann die Chance am größten, dass Sie von der Ergebnisrückmeldung profitieren können.',
        page_3_3: 'Wenn Situationen beschrieben werden, die Sie noch nicht persönlich erlebt haben - dann schätzen Sie bitte ein, wie Sie sich in diesen wahrscheinlich verhalten würden.',
        page_3_4: 'Wenn Ihnen die Einschätzung bei einzelnen Aussagen schwerfallen sollte, wählen Sie bitte die noch am ehesten zutreffende Antwort.',
        page_4_1: 'Das Ausfüllen des Fragebogens wird etwa 15 - 20 Minuten in Anspruch nehmen. Bitte beachten Sie, dass die Zeit, die Sie für die Durchführung benötigen, keine Auswirkung auf das Ergebnis hat. Bitte antworten Sie möglichst spontan. ',
        page_4_2: 'Es gibt weder richtige, noch falschen Antworten.',
        page_4_3: 'Wir behanden Ihre Antworten und Angaben ',
        page_4_4: 'absolut vertraulich.',
        page_4_5: 'Durch die Teilnahme an diesem Assessment bestätigen Sie, dass Sie dies ehrlich und ohne Hinweise von anderen tun und den Inhalt anderen nicht preisgeben.',
        page_4_6: 'Sie können jetzt mit der Bearbeitung der Assessments starten.'
    },
    Assessments: {
        profile_creation_heading: 'Ihr Profil wird nun erstellt. Bitte haben Sie etwas Geduld.',
        profile_creation_1: 'Vielen Dank für Ihre Zeit, Ihr Kommunikations- und Denkstilprofil wird nun erstellt.',
        profile_creation_2: 'Bitte warten Sie auf die Erstellung Ihres Profils. Dies kann einige Sekunden bis Minuten in Anspruch nehmen. Während dieses Vorgangs muss die Seite immer geöffnet bleiben.',
        completed_heading: 'Herzlichen Glückwunsch,',
        completed: 'Sie haben die Assessments Kommunikation und Denkstil nun abgeschlossen. Ihr HR-Ansprechpartner meldet sich in Kürze bei Ihnen.'
    },
    Communication: {
        heading: 'Was passt am besten zu Ihnen?',
        name: 'Assessment - Kommunikation',
        introduction_1: 'Auf der folgenden Seite finden Sie 56 Abschnitte mit jeweils 2 Charakterisierungssätzen. Wählen Sie in jedem Abschnitt den Satz aus, der auf Sie am besten zutrifft.',
        introduction_2: 'Haben Sie dabei Situationen, vorzugsweise im Berufsleben, aber auch im Privatleben (beispielsweise im Sportverein) unter "gleichrangigen" Teamkollegen vor Augen.',
        introduction_3: 'Ihre Auswahl sollte nicht geprägt sein durch Hierarchieverhältnisse von Vorgesetzten / Familie / Verwandtschaft.',
        completed_heading: 'Teil 1 von 2 abgeschlossen!',
        completed: 'Sie haben nun das Assessment Kommunikation abgeschlossen. Klicken Sie auf "Weiter", um mit dem zweiten Assessment fortzufahren, oder auf "Zurück", um Ihre Auswahl zu überprüfen.'
    },
    ThinkingStyle: {
        heading: 'Ich bin eher ...',
        name: 'Assessment - Denkstil',
        introduction: 'Wählen Sie in jeder Zelle ein Wort, welches am besten passt zu der Art wie Sie denken. Welche der beiden Eigenschaften passt besser auf Sie? Wählen Sie diese Eigenschaft entsprechend aus.',
        btn_submit: 'Antworten senden',
        completed_heading: 'Teil 2 von 2 abgeschlossen!',
        completed: 'Sie haben nun das Assessment Denkstil abgeschlossen. Klicken Sie auf "Antworten senden", um die Assessments abzuschließen, oder auf "Zurück", um Ihre Auswahl zu überprüfen.'
    },
    Certificates: {
        heading_comm: 'Kommunikationsprofil',
        description_comm: 'Das Handlungs- und Kommunikationsmodell zeigt in wertfreier Darstellung wie der Mensch in der Kommunikation wahrgenommen werden kann.',
        heading_think: 'Denkstilprofil',
        description_think: 'Dein Profil wurde auf Basis Deiner gegebenen Antworten erstellt und zeigt in wertfreier Darstellung Dein Denkstil auf.',
        fill_time: 'Bearbeitungsdauer',
        privacy_note_1: 'Die Daten sind streng vertraulich und sind ausschließlich ',
        privacy_note_2: 'für Dich und Deinen Ansprechpartner einsehbar.',
        evaluation_note: 'Das Profil darf nur von zertifizierten Personen ausgewertet werden.',
        error_processing: 'Fehler bei der Verarbeitung der Assessment-Daten. Die Wiederherstellung der Daten ist fehlgeschlagen!'
    },
    Internal: {
        heading: 'Internes Dashboard',
        search: 'Suchen',
        show: 'Zeige',
    },
    Archive: {
        download: 'PDF herunterladen',
        placeholder: 'Nach Name filtern...',
        created: 'erstellt',
        comm: 'Kommunikation',
        think: 'Denkstil',
        records: 'Einträge',
        warning_delete_cert: 'Möchten Sie wirklich die Urkunden des Teilnehmers löschen?',
        yes_delete: 'Ja, Urkunden löschen'
    },
    Recovery: {
        results_for: 'Ergebnisse zu ',
        no_results: 'Keine Ergebnisse gefunden zu ',
        heading: 'Urkunden wiederherstellen',
        btn_save_in_archive: 'In Bewerbung ablegen',
        btn_download_pdf: 'PDF herunterladen & in Bewerbung ablegen',
        btn_print: 'Drucken (optimiert für Chromium!)',
        alert_certificates_saved: 'Die Urkunden wurden im Archiv abgelegt.'
    }
}
import React, { createContext, useContext, useState, useEffect, useCallback } from "react"
import { useGlobalState } from "./GlobalStateContext"
import * as html2canvas from 'html2canvas';
import jsPDF from "jspdf"
import axios from 'axios'
import { Cookies } from 'react-cookie'
import { format } from 'date-fns'

const certificatesContext = createContext();

export function CertificatesContext({ children }) {
    const { t, API_PATH, activePage: completed, setActivePage: setCompleted, userData, valuesComm, setValuesComm } = useGlobalState()
    const [base64StrComm, setBase64StrComm] = useState(null)
    const [error, setError] = useState("")
    const date = format(new Date(), 'yyyy-MM-dd');

    const html2ImageStr = useCallback(async (strAssessment, setBase64Str, createPDF) => { /* Return the base64 string from the certificate html and optionally create a PDF file from it. */
        html2canvas(document.getElementById('cert' + strAssessment), {
            scale: 1
        }).then(async canvas => {
            const imgData = await canvas.toDataURL('image/png');
            setBase64Str(imgData);

            if (createPDF === true) {
                const pdf = new jsPDF();
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = Math.floor((imgProps.height * pdfWidth) / imgProps.width);

                let fname = date + ' Sertifikat kommunikasjonsprofil ' + userData.name;

                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(fname + '.pdf');
            }
        }).catch(e => { setError(e.message) })
    }, [userData, date])

    const createCertsAndStoreValues = useCallback(() => {
        axios.post(API_PATH + 'store-assessment-values-in-database.php', { userData: userData, valuesComm: valuesComm, base64StrComm: base64StrComm })
            .then(() => { setCompleted(true) })
            .catch(e => { setError(e.message) })
    }, [API_PATH, base64StrComm, setCompleted, userData, valuesComm])

    useEffect(() => {
        if (completed === false || completed === 'false') {
            const cookies = new Cookies()
            if (Object.keys(valuesComm).length === 0 && cookies.get('valuesComm')) {
                setValuesComm(cookies.get('valuesComm'));
            }
        }
    }, [completed, setValuesComm, valuesComm])

    useEffect(() => {
        if (base64StrComm) {
            createCertsAndStoreValues()
        }
    }, [base64StrComm, createCertsAndStoreValues])

    const generateAssessments = useCallback((generatePDF) => {
        const cookies = new Cookies()
        if (completed === false || completed === 'false' || !completed) {
            if ((Object.keys(valuesComm).length > 0 || cookies.get('valuesComm'))) {
                html2ImageStr('Comm', setBase64StrComm, generatePDF)
            } else {
                setError(t('Certificates.error_processing'))
            }
        }
    }, [t, completed, html2ImageStr, valuesComm])

    const Header = () => {
        return (
            <div id='header-cert' style={{ width: '50%', paddingRight: '2.5rem' }}>
                <img src={process.env.PUBLIC_URL + '/images/bcc/logo.svg'} style={{ float: 'right' }} alt='logo_bcc' />
            </div>
        )
    }

    const MainText = ({ time, date, strAssessment }) => {
        return (
            <>
                <span style={{ marginBottom: '1.55rem', fontSize: '1.4rem', letterSpacing: '0.4rem', textTransform: 'uppercase' }}><b>{t('Certificates.heading_' + strAssessment)}</b></span>
                <span id={'span_username_' + strAssessment} style={{ lineHeight: '1.375', fontSize: '1.2rem', letterSpacing: '0.025rem' }}><b>{userData.firstname} {userData.lastname}</b></span>
                <span id={'span_filltime_' + strAssessment} style={{ fontSize: '0.8rem' }}>{t('Certificates.fill_time')}: {time}</span>
                <span style={{ fontSize: '0.8rem' }}>{date}</span>
                <span id={'span_description_' + strAssessment} style={{ marginTop: '1.55rem', lineHeight: '1.375', width: '510px' }}>{t('Certificates.description_' + strAssessment)}</span>
            </>
        )
    }

    const Footer = ({ strAssessment }) => {
        return (
            <div id={'footer-cert-' + strAssessment} className='footer-cert d-flex flex-row justify-content-between'>
                <div className='text-start' style={{ fontSize: '0.625rem', lineHeight: '1.575' }}>
                    <div id={'span_privacy_note_' + strAssessment}>
                        {t('Certificates.privacy_note_1')}<br />
                        {t('Certificates.privacy_note_2')}
                    </div>
                    <div id={'span_evaluation_note_' + strAssessment}>{t('Certificates.evaluation_note')}</div>
                </div>
                <h6 id={'span_website_' + strAssessment}><b>bcc.no</b></h6>
            </div>
        )
    }

    return (
        <certificatesContext.Provider
            value={{ generateAssessments, Header, MainText, Footer, error, setError }}
        >
            {children}
        </certificatesContext.Provider>
    );
}

export function useCert() {
    return useContext(certificatesContext)
}
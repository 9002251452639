export const TRANSLATIONS_NO = {
    App: {
        lang: 'no',
        title: 'Assessment Center',
        welcome: 'Hjertelig velkommen',
        page: 'Side',
        btn_start: 'Start',
        btn_back: 'Tilbake',
        btn_next: 'Neste',
        btn_previous_page: 'Siste side',
        btn_next_page: 'Neste side',
        cancel: 'Avbryt',
        loading: 'Laster ned data...'
    },
    Nav: {
        dashboard: 'Internt dashbord',
        certificates: 'Sertifikatarkiv',
        recovery: 'Gjenopprett sertifikater',
        config: 'Konfigurasjon',
        account_settings: 'Kontoinnstillinger',
        log_out: 'Logg Ut'
    },
    Auth: {
        heading_login: 'Ansatt pålogging',
        heading_signup: 'Ansatt påmelding',
        heading_mail_config: 'E-post konfigurasjon',
        name: 'Name',
        email: 'Epostadresse',
        password: 'Passord',
        confirm: 'Bekreft passord',
        alert_passwords_must_match: "Passordene må samsvare!",
        log_in: "Logg Inn",
        log_out: "Logg ut",
        sign_up: "Melde deg på",
        reset_password: 'Tilbakestille passord',
        dont_have_an_account: "Har du ikke en konto?",
        already_have_an_account: "Har du allerede en konto?",
        forgot_password: "Glemt passord?"
    },
    Home: {
        firstname: 'Fornavn',
        lastname: 'Etternavn',
        find_your_strengths: "Finn dine styrker!",
    },
    Introduction: {
        introduction: 'Introduksjon',
        page_1_heading: 'Hjertelig velkommen',
        page_1_1: 'De neste sidene vil gjøre deg kjent med undersøkelsen Assessment Center.',
        page_1_2: 'Merk: Undersøkelsen bør kun benyttes av personer over 15 år.',
        page_1_3: 'Målet med undersøkelsen er å gi deg en allsidig beskrivelse av dine personlige egenskaper og interesser. Spørreskjemaet beskriver derfor kjente situasjoner fra en vanlig hverdag og du kan svare hvordan du ville tenkt og reagert i disse situasjonene.',
        page_2_heading: 'Samtykke til databehandling',
        page_2_1: 'Før du starter undersøkelsen vil vi informere deg om hvordan vi behandler dataene dine og hvordan du gir ditt samtykke til databehandlingen.',
        page_2_2: 'Vennligst les informajonen (viktig å lese alt) og merk av i ruten nedenfor for å gi ditt samtykke. Klikk deretter på "neste"-knappen.',
        page_2_3: ' Jeg samtykker til at personopplysningene mine blir lagret. All personlig informasjon behandles konfidensielt og med stor forsiktighet. Dataene blir bare gjort tilgjengelig for nærmeste leder og HR avdelingen.',
        data_protection_info: 'Les personverninformasjonen',
        alert_data_storage: 'Vennligst merk av i boksen ovenfor for å samtykker til at personopplysningene dine blir lagret.',
        notes_heading: 'Vennligst merk',
        page_3_1: 'Vurder utsagnene slik det passer best for deg personlig.',
        page_3_2: 'Ingen av svarene er riktige eller gale. Resultatene vil kun gi et riktig helhetsinntrykk hvis du svarer ærlig. Da er det også størst sjanse for at resultatet vil være nyttig og interessant for deg.',
        page_3_3: 'Noen spørsmål gjelder situasjoner. Dersom det beskrives situasjoner som du ennå ikke har opplevd - svar hvordan du mest sannsynlig ville ha reagert i dem.',
        page_3_4: 'Andre spørsmål gjelder utsagn som beskriver personlighet. Hvis du synes det er vanskelig å vurdere disse - velg alternativet som passer best.',
        page_4_1: 'Det vil ta omtrent 15-20 minutter å fylle ut spørreundersøkelsen. Vær oppmerksom på at tiden du bruker ikke har noen innvirkning på resultatet. Svar så spontant som mulig.',
        page_4_2: 'Det er ingen riktige eller gale svar.',
        page_4_3: 'Dine svar og informasjon blir behandlet ',
        page_4_4: 'helt konfidenselt.',
        page_4_5: 'Ved å delta i undersøkelsen bekrefter du at du svarer ærlig og uten råd fra andre, og at du ikke avslører undersøkelsens innhold til andre.',
        page_4_6: 'Du kan nå begynne spørreundersøkelsen.',
    },
    Assessments: {
        profile_creation_heading: 'Profilen din vil nå bli opprettet. Vær litt tålmodig.',
        profile_creation_1: 'Takk for tiden din. Din kommunikasjonsprofil blir nå opprettet.',
        profile_creation_2: 'Vent til profilen din opprettes. Dette kan ta noen sekunder til minutter. Under denne prosessen må siden alltid være åpen.',
        completed_heading: 'Gratulerer,',
        completed: 'du har fullført kommunikasjonvurderingen. Resultatene blir først gjennomgått muntlig, før det sendes til deg. Din HR-kontaktperson vil ta kontakt med deg innen kort tid.'
    },
    Communication: {
        heading: 'Hva passer deg bedre?',
        name: 'Kommunikasjon',
        introduction_1: 'På de neste sidene finner du 56 utvalgsfelt, med to utsagn i hver.',
        introduction_2: 'I hvert avsnitt velger du det utsagnet som passer best for deg. Når du svarer skal du se for deg situasjoner der de andre personene er "likeverdige" med deg. Du skal altså ikke se for deg hvordan du reagerer overfor arbeidssjefer eller barn, men personer på din egen alder. Du skal helst se for deg at du er på jobb, men det kan også være i mer private sammenhenger (som for eksempel i en sportsklubb). ',
        introduction_3: 'Ditt valg bør altså ikke formes av hierarkiske forhold mellom overordnede eller i familierelasjoner.',
        completed_heading: 'Vurderingen fullført!',
        completed: 'Du har fullført kommunikasjonsvurderingen. Klikk "Neste" for å fullføre vurderingen eller "Tilbake" for å se gjennom svarene dine.'
    },
    ThinkingStyle: {
        heading: 'Jeg er heller ...',
        name: 'Tenkestiler',
        introduction: 'På de neste sidene finner du 64 utvalgsfelt, hver med to egenskaper og forklaringene til dem. Velg ett ord om gangen som passer best til måten du tenker på. Hvilken av de to egenskapene passer deg best? Velg alternativet som passer best.',
        btn_submit: 'Send inn svar',
        completed_heading: 'Del 2 av 2 fullført!',
        completed: 'Du har fullført vurderingen av tenkestiler. Klikk "Send inn svar" for å fullføre vurderingene eller "Tilbake" for å se gjennom svarene dine.'
    },
    Certificates: {
        heading_comm: 'Kommunikasjonsprofil',
        description_comm: 'Handlings- og kommunikasjonsmodellen viser i en verdifri fremstilling hvordan mennesker kan oppfattes i kommunikasjon.',
        heading_think: 'Tenkestilprofil',
        description_think: 'Profilen din ble opprettet basert på svarene dine og viser dine måter å tenke på i en verdifri presentasjon.',
        fill_time: 'Behandlingstid',
        privacy_note_1: 'Dataene er strengt konfidensielle og',
        privacy_note_2: 'kan kun brukes til interne bedriftsformål.',
        evaluation_note: 'Profilen kan kun vurderes av sertifiserte personer!',
        error_processing: 'Feil under behandling av vurderingsdataene. Gjenopprettingen av dataene har mislyktes!'
    },
    Internal: {
        heading: 'Internt dashbord',
        search: 'Søk',
        show: 'Vis'
    },
    Archive: {
        download: 'Last ned PDF',
        placeholder: 'Filtrer etter navn...',
        created: 'opprettet',
        comm: 'Kommunikasjon',
        think: 'Tenkestil',
        records: 'records',
        warning_delete_cert: 'Vil du virkelig slette deltakerens sertifikater?',
        yes_delete: 'Ja, slett sertifikater'
    },
    Recovery: {
        results_for: 'resultater for',
        no_results: 'Ingen resultater funnet for ',
        heading: 'Gjenopprett sertifikater',
        btn_save_in_archive: 'Lagre i arkiv',
        btn_download_pdf: 'Last ned PDF og lagre i arkivet',
        btn_print: 'Skriv ut (best å bruke Chromium)',
        alert_certificates_saved: 'Sertifikatene ble lagret i arkivet.'
    }
}
import React from "react"
import { useGlobalState } from "../contexts/GlobalStateContext";
import { useUserAuth } from "../auth/context/UserAuthContext";
import { Container, Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
const logo = process.env.PUBLIC_URL + '/images/bcc/logo-white.svg'

const Header = () => {
    const { t, setActiveComponent, setActivePage, setCurrentProgress, language, handleLanguageChange } = useGlobalState();
    const { user } = useUserAuth();

    const handleLogoOnClick = () => {
        setActiveComponent('Home');
        setActivePage('1')
        setCurrentProgress(0)
    }

    return (
        <Navbar className='navbar position-relative' id='navbar' expand="sm">
            <Container className="justify-content-center text-align-center" id='nav-container'>
                <Navbar.Brand className='mx-auto position-absolute' href="/" onClick={() => handleLogoOnClick()}><img src={logo} width="125" alt='Logo' /></Navbar.Brand>
                <Navbar.Collapse>
                    {user &&
                        user.uid &&
                        <Nav>
                            <LinkContainer to="/dashboard">
                                <Nav.Link>{t("Nav.dashboard")}</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    }
                </Navbar.Collapse>
                <NavDropdown className='ms-auto' value={language} title={<img src={process.env.PUBLIC_URL + '/images/' + language + '/' + language + '.png'} height='28' alt='language' />}>
                    <Dropdown.Item onClick={() => handleLanguageChange('no')}><img src={process.env.PUBLIC_URL + '/images/no/no.png'} alt='Norsk' height='28' /></Dropdown.Item>
                    <Dropdown.Item onClick={() => handleLanguageChange('en')}><img src={process.env.PUBLIC_URL + '/images/en/en.png'} alt='English' height='28' /></Dropdown.Item>
                    <Dropdown.Item onClick={() => handleLanguageChange('de')}><img src={process.env.PUBLIC_URL + '/images/de/de.png'} alt='Deutsch' height='28' /></Dropdown.Item>
                </NavDropdown>
            </Container>
        </Navbar>
    )
}

export default Header

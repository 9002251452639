import React, { useState } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { useUserAuth } from "../auth/context/UserAuthContext"
import { Link } from "react-router-dom"
import { Toggle, Sidenav, Nav } from 'rsuite';
import { Dashboard, Page, UserBadge, Gear } from '@rsuite/icons';
import 'rsuite/dist/rsuite.min.css'

export const NavSidebar = () => {
  const { t } = useGlobalState();
  const { user, logOut } = useUserAuth();
  const [expanded, setExpanded] = useState(true);

  const handleLogOut = async () => {
    try {
      await logOut()
      alert('You will now be logged out.')
    } catch (err) {
      console.log(err.message);
    }
  }

  return (<>
    {user &&
      user.uid &&
      <div className='position-relative' style={{ width: 240, zIndex: 1 }}>
        <Sidenav className='position-absolute vh-100' expanded={expanded} defaultOpenKeys={['2']}>
          <Toggle className='p-2 mt-1' onChange={setExpanded} checked={expanded} />
          <Sidenav.Body>
            <Nav>
              <Nav.Item eventKey="1" as={Link} to="/dashboard" icon={<Dashboard />}>{t('Nav.dashboard')}</Nav.Item>
              <Nav.Menu eventKey="2" title={t('Nav.certificates')} icon={<Page />}>
                <Nav.Item eventKey="2-1" as={Link} to="/cert-archive">{t('Nav.certificates')}</Nav.Item>
                <Nav.Item eventKey="2-1" as={Link} to="/recovery">{t('Nav.recovery')}</Nav.Item>
              </Nav.Menu>
              <Nav.Item eventKey="3" as={Link} to="/mail-config" icon={<Gear />}>{t('Auth.heading_mail_config')}</Nav.Item>
              <Nav.Menu eventKey="4" title={user.displayName} icon={<UserBadge />}>
                <Nav.Item eventKey="4-1" as={Link} to="/account-settings">{t('Nav.account_settings')}</Nav.Item>
                <Nav.Item eventKey="4-1" onClick={() => handleLogOut()}>{t('Nav.log_out')}</Nav.Item>
              </Nav.Menu>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </div>
    }
  </>)
}

export default NavSidebar
import React from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { AssessmentContext } from "../contexts/AssessmentContext"
import { CertificatesContext } from "../contexts/CertificatesContext"
import { UserAuthContextProvider } from "../auth/context/UserAuthContext"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Alert, Container } from 'react-bootstrap'

import Home from "../components/Home"
import Introduction from "../components/Introduction"
import Communication from "../components/Communication"
import Certificates from "../components/Certificates"
import Header from "./Header"
import Footer from "./Footer"
import NavSidebar from "./NavSidebar"
import Dashboard from "../auth/components/Dashboard"
import CertificateArchive from "./CertificateArchive"
import Recovery from "./Recovery"
import Login from "../auth/components/Login"
import Signup from "../auth/components/Signup"
import ForgotPassword from "../auth/components/ForgotPassword"
import MailConfiguration from "./MailConfiguration"
import AccountSettings from "../auth/components/AccountSettings"
import ProtectedRoute from "../auth/components/ProtectedRoute"

import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/style.css'
import '../css/corporate-design.css'

function App() {
  const { activeComponent, globalError } = useGlobalState()

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'Home': return <Home />
      case 'Introduction': return <Introduction />
      case 'Communication': return <AssessmentContext><Communication /></AssessmentContext>
      case 'Certificates': return <CertificatesContext><Certificates /></CertificatesContext>
      default: return <Home />
    }
  }

  return (
    <div className="App d-flex flex-column min-vh-100">
      <Router basename='/v3/'>
        <UserAuthContextProvider>
          <NavSidebar />
          <Header />
          <Container id='main-container' className='position-relative p-0 d-flex flex-column align-items-center'>
            <Routes>
              <Route path="/" element={
                <Container id='main-container-assessment' className='position-relative box d-flex flex-column justify-content-center'>
                  {globalError && <Alert className='mb-2' variant="danger">{globalError}</Alert>}
                  {renderActiveComponent()}
                </Container>
              } key={document.location.href} />
              <Route path="/login" element={<Login />} key={document.location.href} />
              <Route path="/signup" element={<Signup />} key={document.location.href} />
              <Route path="/forgot-password" element={<ForgotPassword />} key={document.location.href} />
              <Route path='/dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} key={document.location.href} />
              <Route path='/cert-archive' element={<ProtectedRoute><CertificateArchive /></ProtectedRoute>} key={document.location.href} />
              <Route path="/recovery" element={<ProtectedRoute><CertificatesContext><Recovery /></CertificatesContext></ProtectedRoute>} key={document.location.href} />
              <Route path="/mail-config" element={<ProtectedRoute><MailConfiguration /></ProtectedRoute>} key={document.location.href} />
              <Route path="/account-settings" element={<ProtectedRoute><AccountSettings /></ProtectedRoute>} key={document.location.href} />
            </Routes>
          </Container>
          <Footer activeComponent={activeComponent} />
        </UserAuthContextProvider>
      </Router>
    </div>
  );
}

export default App;

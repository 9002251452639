export const TRANSLATIONS_EN = {
    App: {
        lang: 'en',
        title: 'Assessment Center',
        welcome: 'Heartily welcome',
        page: 'Page',
        btn_start: 'Start',
        btn_back: 'Back',
        btn_next: 'Next',
        btn_previous_page: 'Previous Page',
        btn_next_page: 'Next Page',
        cancel: 'Cancel',
        loading: 'Loading...'
    },
    Nav: {
        dashboard: 'Internal dashboard',
        certificates: 'Certificate archive',
        recovery: 'Recover certificates',
        config: 'Configuration',
        account_settings: 'Account settings',
        log_out: 'Log Out'
    },
    Auth: {
        heading_login: 'Employee Login',
        heading_signup: 'Employee Signup',
        heading_mail_config: 'Email configuration',
        name: 'Name',
        email: 'Email address',
        password: 'Password',
        confirm: 'Confirm Password',
        alert_passwords_must_match: 'The passwords must match',
        log_in: 'Log In',
        log_out: 'Log Out',
        sign_up: 'Sign Up',
        reset_password: 'Reset Password',
        dont_have_an_account: 'Don\'t have an account?',
        already_have_an_account: 'Already have an account?',
        forgot_password: 'Forgot Password?'
    },
    Home: {
        firstname: 'First name',
        lastname: 'Last name',
        find_your_strengths: "Find your strengths!",
    },
    Introduction: {
        introduction: 'Introduction',
        page_1_heading: 'Heartily welcome',
        page_1_1: 'The next pages will familiarize you with the way the Assessment Center operates.',
        page_1_2: 'Please note that this assessment should only be completed by those 15 years old and above.',
        page_1_3: 'This survey aims to give you nuanced feedback on your preferred way of doing things in everyday life. The following questions include statements about your personal way of communicating and thinking in different situations that could be important to your professional activities.',
        page_2_heading: 'Consent to Data Handling',
        page_2_1: 'Before you begin the assessment, we will inform you how we will process your data and how you can consent to the handling of the data.',
        page_2_2: 'Please read the information (it is important that you read everything!). Check the box in order to give your consent, then click the "Next" button.',
        page_2_3: ' I consent to my personal data being stored. All personal information will be handled confidentially and with utmost care. The data will only be available to Human Resources.',
        data_protection_info: 'Read the privacy information',
        alert_data_storage: 'Please check the box above to consent to the storage of your personal data.',
        notes_heading: 'Please note',
        page_3_1: 'Please rate the statements in a way that best fits to you.',
        page_3_2: 'There are no correct or incorrect answers. The only way to ensure a logical and consistent result, is through honest answers. This also provides the best chance of you being able to profit from the feedback given by the results.',
        page_3_3: 'If situations are described which you have not yet experienced personally, please judge how you would most likely react.',
        page_3_4: 'If certain statements are difficult to rate, please choose the answer that fits you best.',
        page_4_1: 'The survey will take approximately 15-20 minutes. Please note that the time you take to complete the survey has no effect on the results. Please answer as spontaneously as possible. ',
        page_4_2: 'There are no correct or incorrect answers',
        page_4_3: 'Your answers and information will be handled with ',
        page_4_4: 'absolute confidentiality.',
        page_4_5: 'By participating in this assessment, you acknowledge that you are taking part honestly and without guidance from others. Furthermore you will not disclose the content to others.',
        page_4_6: 'You may now begin the assessment.',
    },
    Assessments: {
        profile_creation_heading: 'Your profile will now be created. Please have a little patience.',
        profile_creation_1: 'Thank you for your time, your communication and thinking preferences profile is now being created.',
        profile_creation_2: 'Please wait for your profile to be created. This may take a few seconds to minutes. During this process, the page must always remain open.',
        completed_heading: 'Congratulations, ',
        completed: 'You just completed the Communication and Thinking Styles assessments. Your HR contact person will get in touch with you shortly.'
    },
    Communication: {
        heading: 'What fits you best?',
        name: 'Assessment - Communication',
        introduction_1: 'On the following pages you will find 56 selection fields, each with two character statements.',
        introduction_2: 'For each section, select the statement which fits you best. Have situations with your peers in mind - preferably from your professional life, but also from your private life (for example on a sports team).',
        introduction_3: 'Your selections should not be based on hierarchical relationships with superiors / family / relatives.',
        completed_heading: 'Part 1 of 2 completed!',
        completed: 'You have now completed the Communication assessment. Click "Next" to continue with the second assessment or "Back" to review your answers.'
    },
    ThinkingStyle: {
        heading: 'I am rather ...',
        name: 'Assessment - Thinking Styles',
        introduction: 'On the following pages you will find 64 selection fields, each with two characteristics and the explanations for them. In each section, choose the word that best fits the way you think. Which of the two characteristics fits you better? Select this characteristic accordingly.',
        btn_submit: 'Submit answers',
        completed_heading: 'Part 2 of 2 completed!',
        completed: 'You have now completed the Thinking Styles assessment. Click "Submit Answers" to complete the assessments or "Back" to review your answers.'
    },
    Certificates: {
        heading_comm: 'Communication profile',
        description_comm: 'The action and communication model shows in a value-free presentation how people can be perceived in communication.',
        heading_think: 'Thinking preferences',
        description_think: 'Your profile was created based on your answers and displays your thinking preferences ranked from strong to low.',
        fill_time: 'Processing time',
        privacy_note_1: 'The data is strictly confidential and may ',
        privacy_note_2: 'only be used for internal company purposes.',
        evaluation_note: 'The profile may only be evaluated by certified persons.',
        error_processing: 'Error while processing the assessment data. The recovery of the data has failed!'
    },
    Internal: {
        heading: 'Internal Dashboard',
        search: 'Search',
        show: 'Show'
    },
    Archive: {
        download: 'Download PDF',
        placeholder: 'Filter by name...',
        created: 'created at',
        comm: 'Communication',
        think: 'Thinking Styles',
        records: 'records',
        warning_delete_cert: 'Do you really want to delete the participant´s certificates?',
        yes_delete: 'Yes, delete certificates'
    },
    Recovery: {
        results_for: 'results for',
        no_results: 'No results found for ',
        heading: 'Recover certificates',
        btn_save_in_archive: 'Store in application',
        btn_download_pdf: 'Download PDF & store in application',
        btn_print: 'Print (optimized for Chromium!)',
        alert_certificates_saved: 'The certificates were stored in the archive.'
    }
}